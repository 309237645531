import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  InputBase,
  MenuItem,
  Select,
} from '@mui/material';
import { styled as styledMUI } from '@mui/material/styles';
import { useAppSelector } from 'hooks/reduxHooks';
import { getConfiguration } from 'modules/GlobalSelectors';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../../theme/patterns/colors';
import { Organization } from './SelectOrganizationReducer';

type SelectOrganizationProps = {
  data: Array<Organization>;
  loading: boolean;
  onOrganizationSelect: (
    organization: Organization,
    showOrganizationUrl: boolean,
  ) => void;
  selectedOrganization: Organization | null;
};

const SelectOrganization: FC<SelectOrganizationProps> = (props) => {
  const { data, loading, onOrganizationSelect, selectedOrganization } = props;
  const { data: configuration, loading: isConfigurationLoading } =
    useAppSelector(getConfiguration);

  const [organizationName, setOrganizationName] = useState<string>();

  useEffect(() => {
    if (data?.length > 0 && !organizationName) {
      setOrganizationName(data[0].name_display);
    }
  }, [data, organizationName]);

  const showOrganizationSelect =
    !loading &&
    data?.length > 1 &&
    !isConfigurationLoading &&
    configuration?.showOrganizationDropdown;

  const handleChange = (event: { target: { value: string } }) => {
    onOrganizationSelect(
      data.find((org) => org.name_display === event.target.value) ?? data[0],
      configuration?.showOrganizationUrl ?? false,
    );
  };
  const renderValue = (selected: any) => <StyledText>{selected}</StyledText>;

  return (
    <>
      {showOrganizationSelect && (
        <OrganizationWrapper>
          <FormControl sx={{ m: 1 }} variant="standard">
            <CustomSelect
              id="organization_id"
              value={selectedOrganization?.name_display}
              onChange={(e) => handleChange(e as any)}
              input={<CustomInput />}
              IconComponent={KeyboardArrowDownIcon}
              renderValue={renderValue}
            >
              {data?.map((organization) => (
                <CustomMenuItem
                  key={organization.name_display}
                  value={organization.name_display}
                >
                  <OrganizationInfoWrapper>
                    <Avatar
                      sx={{ marginRight: '16px' }}
                    >{`${organization.name_display[0].toUpperCase()}${organization.name_display
                      .at(-1)
                      ?.toUpperCase()}`}</Avatar>
                    <MenuItemText>{organization.name_display}</MenuItemText>
                  </OrganizationInfoWrapper>
                  {selectedOrganization?.name_display ===
                    organization.name_display && (
                    <StyledCheckIcon color="primary" fontSize="medium" />
                  )}
                </CustomMenuItem>
              ))}
              <Divider />
              <ButtonsWrapper>
                <CustomButton variant="text" disabled={true}>
                  <CorporateFareIcon
                    style={{
                      marginLeft: 12,
                      marginRight: 36,
                      lineHeight: 24,
                    }}
                  />
                  Manage Organizations
                </CustomButton>
                <CustomButton variant="text" disabled={true}>
                  <AddIcon
                    style={{
                      marginLeft: 12,
                      marginRight: 36,
                      lineHeight: 24,
                    }}
                  />
                  Create New Organization
                </CustomButton>
              </ButtonsWrapper>
            </CustomSelect>
          </FormControl>
        </OrganizationWrapper>
      )}
    </>
  );
};

const StyledCheckIcon = styled(CheckIcon)``;

const StyledText = styled.span`
  display: flex;
  align-items: center;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CustomButton = styled(Button)`
  justify-content: start !important;
  font-size: 14px;
`;

const CustomInput = styledMUI(InputBase)(() => ({
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: colors.grey200,
    border: 'none',
    fontSize: 13,
    fontWeight: 500,
    padding: '0 8px 0 0',
    color: '#000000',
    paddingRight: 16,
  },

  '& svg': {
    color: '#000000',
  },

  '&:focus': {
    backgroundColor: 'inherit',
  },
}));

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
const OrganizationWrapper = styled.div``;

const OrganizationInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CustomSelect = styled(Select)`
  & .MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px;
  }

  & .MuiList-root {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const CustomMenuItem = styled(MenuItem)`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 284px;
`;

const MenuItemText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default SelectOrganization;
