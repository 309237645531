import { ReactNode } from 'react';
import styled from 'styled-components';

interface ModalContentProps extends React.ComponentPropsWithoutRef<'div'> {
  children: ReactNode;
}

const ModalContent = ({ children, ...props }: ModalContentProps) => (
  <Content {...props}>{children}</Content>
);

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 16px 10px 16px;
  overflow-y: auto;
`;

export default ModalContent;
