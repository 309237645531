import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { SelectWithModelChoices } from 'components/FormFields';
import { ScatterSettingsType } from 'modules/visualize/VisualizeReducer';
import React, { memo } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import colors from 'theme/patterns/colors';
import Accordion from './Accordion';

interface PointsSettingsProps {
  isExpanded: boolean;
  control: Control<ScatterSettingsType>;
  groupByChoices: any[];
  colorByChoices: any[];
  labelByChoices: any[];
  setValue: UseFormSetValue<ScatterSettingsType>;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  formValues: Partial<ScatterSettingsType>;
}

const PointsSettings: React.FC<PointsSettingsProps> = memo(
  ({
    isExpanded,
    control,
    groupByChoices,
    colorByChoices,
    labelByChoices,
    formValues,
    onChange,
    setValue,
  }) => {
    const {
      groupBy: groupByValue,
      colorBy: colorByValue,
      showLegend,
    } = formValues;

    return (
      <Accordion
        panelSummary="Points"
        panelDetails={
          <div>
            <OptionsWrapper>
              <SelectWithModelChoices
                choices={groupByChoices}
                fieldName="groupBy"
                label="Group by"
                control={control}
                forceShowClearButton={!!groupByValue}
                withoutIcon
                notRequired
                disabled
              />
              <SelectWithModelChoices
                choices={colorByChoices}
                fieldName="colorBy"
                label="Color by"
                control={control}
                forceShowClearButton={!!colorByValue}
                withoutIcon
                notRequired
                disabled
              />
            </OptionsWrapper>

            <SwitchOptionsWrapper>
              <FormGroup>
                <CustomFormControlLabel
                  control={<Switch size="small" />}
                  label="Show legend"
                  onChange={(event, checked) =>
                    setValue('showLegend', checked, {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                  value={showLegend}
                  checked={showLegend}
                />
              </FormGroup>
            </SwitchOptionsWrapper>

            <MultipleOptionsWrapper>
              <SelectWithModelChoices
                choices={labelByChoices}
                fieldName="labelBy"
                label="Label by"
                control={control}
                withoutIcon
                multiple
                notRequired
              />
            </MultipleOptionsWrapper>
          </div>
        }
        expanded={isExpanded}
        onChange={onChange}
      />
    );
  },
);

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .MuiFilledInput-root {
    padding-top: 12px;
    padding-right: 25px !important;
  }

  .MuiInputLabel-root {
    top: -2px;
  }

  .MuiFilledInput-input {
    padding-bottom: 4px !important;
  }
`;

const MultipleOptionsWrapper = styled(OptionsWrapper)`
  .MuiFilledInput-root {
    min-height: 46px;
    padding-top: 20px;
    padding-right: 20px !important;
  }

  .MuiAutocomplete-root {
    & .MuiFilledInput-input {
      padding-top: 0px;
    }
  }

  .MuiAutocomplete-tag {
    margin: 0px !important;
    margin-bottom: 4px !important;
    margin-right: 2px !important;
    max-height: 25px;
    background-color: ${colors.grey100};
    border: 1px solid ${colors.lightGrey};

    & .MuiChip-label {
      padding-left: 6px;
      padding-right: 8px;
    }

    & .MuiChip-deleteIcon {
      font-size: 18px;
    }
  }

  .MuiAutocomplete-clearIndicator {
    margin-right: -7px;
  }
`;

const SwitchOptionsWrapper = styled.div`
  margin: 5px 0;

  .MuiFormGroup-root {
    padding-left: 4px;
  }
`;

const CustomFormControlLabel = styled(FormControlLabel)<{ $withGap?: boolean }>`
  margin-right: 15px;

  margin-top: ${({ $withGap }) => ($withGap ? '3px' : 0)};
  margin-bottom: ${({ $withGap }) => ($withGap ? '3px' : 0)};

  .MuiRadio-root {
    padding: 5px;
  }

  .MuiRadio-root:hover {
    padding: 5px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
  }
`;

export default PointsSettings;
