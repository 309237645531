import { getExpirationDate } from 'modules/auth/utils';

export const setTokenData = (tokenObj: Record<string, any>) => {
  localStorage.setItem(
    'expirationTokenDate',
    getExpirationDate(tokenObj.expires_in),
  );
  localStorage.setItem('refresh_token', tokenObj.refresh_token);
  localStorage.setItem('access_token', tokenObj.access_token);
};

export const getRefreshToken = () => localStorage.getItem('refresh_token');
export const getAccessToken = () => localStorage.getItem('access_token');

export const getExpirationDateToken = () =>
  localStorage.getItem('expirationTokenDate');

export const clearTokenData = () => {
  localStorage.removeItem('expirationTokenDate');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');
};
