import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TextField } from '@mui/material';
import { DOESettingsType } from 'modules/visualize/VisualizeReducer';
import React from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { chartNumericFieldValidator } from 'utils/fieldValidators';
import Accordion from './Accordion';

interface AxesSettingsProps {
  isExpanded: boolean;
  register: UseFormRegister<DOESettingsType>;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
  errors: Partial<FieldErrorsImpl<DOESettingsType>>;
  children?: React.ReactNode;
}

const AxesSettings: React.FC<AxesSettingsProps> = ({
  onChange,
  register,
  errors,
  isExpanded,
}) => {
  const showErrorButton =
    (errors.xMinAxis ||
      errors.xMaxAxis ||
      errors.yMinAxis ||
      errors.yMaxAxis) &&
    !isExpanded;

  return (
    <Accordion
      panelSummary={
        <PanelSummary>
          Axes
          {showErrorButton ? <ErrorOutlineIcon color="error" /> : null}
        </PanelSummary>
      }
      panelDetails={
        <div>
          <AxesOptionWrapper>
            <SizeInputsWrapper>
              <CustomTextField
                id="xMinAxis"
                variant="filled"
                margin="dense"
                label="X Min"
                fullWidth
                style={{
                  marginRight: 10,
                }}
                error={!!errors.xMinAxis?.message}
                helperText={errors.xMinAxis?.message ?? ''}
                InputProps={{
                  startAdornment: <></>,
                }}
                {...register('xMinAxis', chartNumericFieldValidator())}
                disabled
              />
              <CustomTextField
                id="xMaxAxis"
                variant="filled"
                margin="dense"
                label="X Max"
                fullWidth
                error={!!errors.xMaxAxis?.message}
                helperText={errors.xMaxAxis?.message ?? ''}
                InputProps={{
                  startAdornment: <></>,
                }}
                {...register('xMaxAxis', chartNumericFieldValidator())}
                disabled
              />
            </SizeInputsWrapper>
            <SizeInputsWrapper>
              <CustomTextField
                id="yMinAxis"
                variant="filled"
                margin="dense"
                label="Y Min"
                fullWidth
                style={{
                  marginRight: 10,
                }}
                error={!!errors.yMinAxis?.message}
                helperText={errors.yMinAxis?.message ?? ''}
                InputProps={{
                  startAdornment: <></>,
                }}
                {...register('yMinAxis', chartNumericFieldValidator())}
              />
              <CustomTextField
                id="yMaxAxis"
                variant="filled"
                margin="dense"
                label="Y Max"
                fullWidth
                error={!!errors.yMaxAxis?.message}
                helperText={errors.yMaxAxis?.message ?? ''}
                InputProps={{
                  startAdornment: <></>,
                }}
                {...register('yMaxAxis', chartNumericFieldValidator())}
              />
            </SizeInputsWrapper>
          </AxesOptionWrapper>
        </div>
      }
      expanded={isExpanded}
      onChange={onChange}
    />
  );
};

const SizeInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CustomTextField = styled(TextField)`
  margin: 0;

  .MuiFormLabel-root {
    top: -2px;
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 6px;
  }

  .MuiFormHelperText-root.Mui-error {
    margin-left: 0;
    margin-right: 0;
  }
`;

const AxesOptionWrapper = styled.div``;

const PanelSummary = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & svg {
    margin: 0 8px;
  }
`;

export default AxesSettings;
