import * as R from 'ramda';

import {
  STATE_KEY as AUTH_STATE_KEY,
  InitialAuthStateType,
} from './AuthReducer';

export const getUserInfo = (
  state: InitialAuthStateType,
): InitialAuthStateType['userInfo']['data'] =>
  R.pathOr('', [AUTH_STATE_KEY, 'userInfo', 'data'], state);
export const isUserInfoLoading = (state: InitialAuthStateType): string =>
  R.pathOr('', [AUTH_STATE_KEY, 'userInfo', 'loading'], state);

export const getAccessToken = (state: InitialAuthStateType): string =>
  R.pathOr('', [AUTH_STATE_KEY, 'auth', 'data', 'access_token'], state);
export const getAuthData = (
  state: InitialAuthStateType,
): InitialAuthStateType['auth'] =>
  R.pathOr('', [AUTH_STATE_KEY, 'auth'], state);

export const isAuthLoading = (state: InitialAuthStateType): boolean =>
  R.pathOr('', [AUTH_STATE_KEY, 'auth', 'loading'], state);

export const getPwChangeData = (
  state: InitialAuthStateType,
): InitialAuthStateType['pwChange'] =>
  R.pathOr('', [AUTH_STATE_KEY, 'pwChange'], state);
export const getPwForgotData = (
  state: InitialAuthStateType,
): InitialAuthStateType['pwForgot'] =>
  R.pathOr('', [AUTH_STATE_KEY, 'pwForgot'], state);
export const getPwForgotConfirmData = (
  state: InitialAuthStateType,
): InitialAuthStateType['pwForgotConfirm'] =>
  R.pathOr('', [AUTH_STATE_KEY, 'pwForgotConfirm'], state);
