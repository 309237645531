import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { Backdrop, Fade, Modal as MaterialModal } from '@mui/material/';
import { useAppSelector } from 'hooks/reduxHooks';
import { getBlocksModalInfo } from 'modules/explore/ExploreSelectors';

interface ModalProps {
  children: ReactNode;
  open: boolean;
  handleClose: (clickCloseModal?: boolean) => void;
  contentWithScroll?: boolean;
  paperStyles?: CSSProperties;
}

const Modal = ({
  children,
  open,
  handleClose,
  contentWithScroll = true,
  paperStyles,
  ...rest
}: ModalProps) => {
  const { showModals: blocksModalsOpen } = useAppSelector(getBlocksModalInfo);

  return (
    <ModalStyled
      open={open}
      onClose={(e: MouseEvent) => {
        // as we can open multi-modal containers (blocks, events, stack-modals),
        // need to add this checking to prevent closing blocks modals if we close stack-modals
        blocksModalsOpen && e.stopPropagation();
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      {...rest}
    >
      <Fade in={open}>
        <PaperStyled contentWithScroll={contentWithScroll} style={paperStyles}>
          {children}
        </PaperStyled>
      </Fade>
    </ModalStyled>
  );
};

const ModalStyled = styled(MaterialModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaperStyled = styled.div<{ contentWithScroll: boolean }>`
  width: 640px;
  height: ${({ contentWithScroll }) => (contentWithScroll ? '95vh' : 'auto')};
  margin: 20px 0;
  border-radius: 4px;
  background-color: #fff;
  box-shadow:
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2);
  outline: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export default Modal;
