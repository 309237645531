import { ReactNode } from 'react';
import styled from 'styled-components';

interface ModalFormWrapProps {
  children: ReactNode;
  props?: Record<string, any>;
}

const ModalFormWrap = ({ children, props }: ModalFormWrapProps) => (
  <FormWrap {...props}>{children}</FormWrap>
);

const FormWrap = styled.div`
  width: 100%;

  .MuiFormHelperText-root {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 90%;
    max-height: 35px;
    overflow: auto;
    margin: 0;
    padding-left: 12px;
    font-size: 10px;
    z-index: 1;
    color: #f44336;
    border-radius: 0 0 4px 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  }
`;

export default ModalFormWrap;
