import {
  FormControlLabel,
  FormGroup,
  Slider,
  Switch,
  Typography,
} from '@mui/material';
import { SelectWithModelChoices } from 'components/FormFields';
import { DOESettingsType } from 'modules/visualize/VisualizeReducer';
import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import colors from 'theme/patterns/colors';
import Accordion from './Accordion';

interface PointsSettingsProps {
  isExpanded: boolean;
  control: Control<DOESettingsType>;
  colorByChoices: any[];
  labelByChoices: any[];
  formValues: Partial<DOESettingsType>;
  setValue: UseFormSetValue<DOESettingsType>;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
  ) => void;
}

const PointsSettings: React.FC<PointsSettingsProps> = ({
  isExpanded,
  control,
  colorByChoices,
  labelByChoices,
  formValues,
  onChange,
  setValue,
}) => {
  const { colorBy: colorByValue, showLegend, showLabels, jitter } = formValues;

  return (
    <Accordion
      panelSummary="Points"
      panelDetails={
        <div>
          <OptionsWrapper>
            <SelectWithModelChoices
              choices={colorByChoices}
              fieldName="colorBy"
              label="Color by"
              control={control}
              forceShowClearButton={!!colorByValue}
              withoutIcon
              disabled
            />
          </OptionsWrapper>

          <SwitchOptionsWrapper>
            <FormGroup>
              <CustomFormControlLabel
                control={<Switch size="small" />}
                label="Show legend"
                onChange={(event, checked) => setValue('showLegend', checked)}
                checked={showLegend}
              />
            </FormGroup>
          </SwitchOptionsWrapper>

          <MultipleOptionsWrapper>
            <SelectWithModelChoices
              choices={labelByChoices}
              fieldName="labelBy"
              label="Label by"
              control={control}
              withoutIcon
              multiple
              notRequired
            />
          </MultipleOptionsWrapper>

          <SwitchOptionsWrapper>
            <FormGroup>
              <CustomFormControlLabel
                control={<Switch size="small" />}
                label="Show labels"
                onChange={(event, checked) => setValue('showLabels', checked)}
                checked={showLabels}
                disabled
              />
            </FormGroup>
          </SwitchOptionsWrapper>

          <JitterWrapper>
            <Typography
              style={{
                fontSize: 14,
              }}
              variant="body1"
            >
              Jitter
            </Typography>
            <CustomSlider
              aria-label="Temperature"
              valueLabelDisplay="auto"
              defaultValue={0}
              value={jitter}
              step={0.1}
              min={0}
              max={0.5}
              disabled
              size="small"
              onChange={(_, value) => {
                setValue('jitter', value as number);
              }}
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                {
                  value: 0.5,
                  label: '0.5',
                },
              ]}
            />
          </JitterWrapper>
        </div>
      }
      expanded={isExpanded}
      onChange={onChange}
    />
  );
};

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .MuiFilledInput-root {
    padding-top: 12px;
    padding-right: 25px !important;
  }

  .MuiInputLabel-root {
    top: -2px;
  }

  .MuiFilledInput-input {
    padding-bottom: 4px !important;
  }
`;

const MultipleOptionsWrapper = styled(OptionsWrapper)`
  .MuiFilledInput-root {
    min-height: 46px;
    padding-top: 20px;
  }

  .MuiAutocomplete-root {
    & .MuiFilledInput-input {
      padding-top: 0px;
    }
  }

  .MuiAutocomplete-tag {
    margin: 0px !important;
    margin-bottom: 4px !important;
    margin-right: 2px !important;
    max-height: 25px;
    background-color: ${colors.grey100};
    border: 1px solid ${colors.lightGrey};

    & .MuiChip-label {
      padding-left: 6px;
      padding-right: 8px;
    }

    & .MuiChip-deleteIcon {
      font-size: 18px;
    }
  }
`;

const SwitchOptionsWrapper = styled.div`
  margin: 5px 0;

  .MuiFormGroup-root {
    padding-left: 4px;
  }
`;

const CustomFormControlLabel = styled(FormControlLabel)<{ $withGap?: boolean }>`
  margin-right: 15px;

  margin-top: ${({ $withGap }) => ($withGap ? '3px' : 0)};
  margin-bottom: ${({ $withGap }) => ($withGap ? '3px' : 0)};

  .MuiRadio-root {
    padding: 5px;
  }

  .MuiRadio-root:hover {
    padding: 5px;
  }

  .MuiFormControlLabel-label {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
  }
`;

const JitterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const CustomSlider = styled(Slider)`
  width: calc(100% - 5px);
  margin-left: 5px;

  & .MuiSlider-markLabel {
    top: 20px;
    font-size: 12px;
  }
`;

export default PointsSettings;
